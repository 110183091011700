<template>
  <div class="docs-components">
    <div class="docs-components__body">
      <DocsUpload
        v-for="(item, index) in project ? imgDocs : noProjectImgDocs"
        :key="index"
        :project="project"
        :label="item.label"
        :currentDocsOrientation="getOrientation(item.key_document)"
        :info-text="getTooltip(item.key_document)"
        :file-loaded="
          user[item.type].document_files &&
          user[item.type].document_files[item.key_document]?.full
        "
        :keyName="item.key_document"
        :type="type"
        :item-type="item.type"
        :has-file="is_disabled"
        :is-required="item.is_required"
        :error="
          (project && v$.formData[item.key_document].$errors?.length !== 0) ||
          (project &&
            this.user.info_validation_documents?.data.length > 0 &&
            this.user.info_validation_documents?.data?.find((i) => {
              return i.key_document === item.key_document;
            }) &&
            this.user.info_validation_documents?.data?.find((i) => {
              return i.key_document === item.key_document;
            }).status === 40)
        "
      />

      <PDFInputFile
        v-for="(item, index) in project ? pdfDocs : noProjectPdfDocs"
        :key="index"
        :label="item.label"
        name="Выбрать файл"
        :keyName="item.key_document"
        :info-text="getTooltip(item.key_document)"
        :is-required="item.is_required"
        :type="type"
        :item-type="item.type"
        :file-loaded="
          user[item.type].document_files &&
          user[item.type].document_files[item.key_document]?.full
        "
        :has-file="is_disabled"
        :error="
          (project && v$.formData[item.key_document].$errors?.length !== 0) ||
          (project &&
            this.user.info_validation_documents?.data.length > 0 &&
            this.user.info_validation_documents?.data?.find((i) => {
              return i.key_document === item.key_document;
            }) &&
            this.user.info_validation_documents?.data?.find((i) => {
              return i.key_document === item.key_document;
            }).status === 40)
        "
        @file-change="
          (value) => {
            this.pdfUpdate(value, item.key_document);
          }
        "
      />

      <p>* - обязательные документы</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DocsUpload from "@/components/Blocks/DocsUpload";
import documentsLabels from "@/enums/documentsLabels";
import PDFInputFile from "@/components/Blocks/PDFInputFile";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import moment from "moment";

export default {
  name: "DocsComponent",
  components: { PDFInputFile, DocsUpload },
  emits: ["docs-changed", "up-docs"],
  props: [
    "currentProject",
    "project",
    "type",
    "category_id",
    "nomination_id",
    "is_disabled",
    "without_validations",
  ],
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  watch: {
    docs: {
      handler: function () {
        Object.keys(this.formData)?.forEach((item) => {
          return (this.formData[item] = this.docs[item]);
        });
      },
      deep: true,
    },
    formData: {
      handler: async function () {
        const isFormCorrect = await this.v$.$validate();
        // if (!isFormCorrect && !this.without_validations) {
        //   this.$store.dispatch("validationErrors", {
        //     errors: this.v$.$errors,
        //     fieldsInfo: this.isAllDocsLabels,
        //   });
        //   return;
        // }

        this.$emit("docs-changed", isFormCorrect, this.v$.$errors);
      },
      deep: true,
    },
    docsWithKeys: {
      handler: function () {
        this.$emit("up-docs", this.docsWithKeys);
      },
      deep: true,
    },
  },
  validations() {
    let validationsData = {};
    if (this.project) {
      if (this.type === "passport") {
        this.docsWithKeys?.forEach((item) => {
          if (
            (this.user?.passport?.document_files &&
              !this.user?.passport?.document_files[item.key_document]?.full &&
              item.is_required) ||
            (this.user.info_validation_documents?.data.length > 0 &&
              this.user.info_validation_documents?.data?.find((i) => {
                return i.key_document === item.key_document;
              }) &&
              this.user.info_validation_documents?.data?.find((i) => {
                return i.key_document === item.key_document;
              }).status === 40)
          ) {
            validationsData[item.key_document] = {
              required: helpers.withMessage(
                "Файл должен быть загружен",
                required
              ),
            };
          } else {
            validationsData[item.key_document] = { required: {} };
          }
        });
      }
      if (this.type === "education") {
        this.docsWithKeys?.forEach((item) => {
          if (
            (this.user[item.type]?.document_files &&
              !this.user[item.type]?.document_files[item.key_document]?.full &&
              item.is_required) ||
            (this.user.info_validation_documents?.data.length > 0 &&
              this.user.info_validation_documents?.data?.find((i) => {
                return i.key_document === item.key_document;
              }) &&
              this.user.info_validation_documents?.data?.find((i) => {
                return i.key_document === item.key_document;
              }).status === 40)
          ) {
            validationsData[item.key_document] = {
              required: helpers.withMessage(
                "Файл должен быть загружен",
                required
              ),
            };
          } else {
            validationsData[item.key_document] = { required: {} };
          }
        });
      }
    }
    return { formData: validationsData };
  },
  data() {
    return {
      documentsLabels,
      formData: {
        // foreign passport
        document_foreign_passport_main_page: "",
        document_foreign_passport_registration_page: "",
        document_foreign_passport_translate: "",
        document_migration_card_front: "",
        document_migration_card_back: "",
        document_registration_sheet_front: "",
        document_registration_sheet_back: "",
        document_tin: "",
        // native passport
        document_passport_main_page: "",
        document_passport_register_page: "",
        document_passport_6_7_page: "",
        document_passport_old_version_page: "",
        document_inn: "",
        // education
        document_student_billet: "",
        document_student_record_book_main_page: "",
        document_student_record_book_session_pages_one: "",
        document_student_record_book_session_pages_two: "",
        document_student_record_book_session_pages_three: "",
        document_student_record_book_session_pages_four: "",
        document_student_record_book_session_pages_five: "",
        document_reference: "",
        document_higher_education: "",
        document_social_need: "",
        document_certificate_absence_academic_debt: "",
        document_certificate_guardianship_adoption: "",
        document_certificate_family_composition: "",
        document_reference_scholarship_amount: "",
        document_certificate_personal_income_student_last_3_months: "",
        document_consent_parents: "",
        document_reference_income_parent_one: "",
        document_reference_income_parent_two: "",
      },
    };
  },
  computed: {
    is18yearsOld() {
      const years18Ago = moment().subtract(18, "years");
      return !moment(this.user.birthday).isAfter(years18Ago);
    },

    course_level() {
      return this.user.info_course_level.id;
    },
    level_education() {
      return this.user.info_level_education.id;
    },

    documentSettings() {
      return this.project
        ? this.currentProject?.settings?.need_validation_student_documents?.data
        : this.allTypeDocs || [];
    },
    documentSettingsByType() {
      let buffer = [];
      let bufferItem = {};
      if (this.type === "passport") {
        if (this.disabledFields["isCitizenRussia"]) {
          this.findDocumentSettingsByType(
            this.russianPassportDocs,
            buffer,
            bufferItem
          );
        }
        if (!this.disabledFields["isCitizenRussia"]) {
          this.findDocumentSettingsByType(
            this.foreignPassportDocs,
            buffer,
            bufferItem
          );
        }
      }
      if (this.type === "education") {
        this.findDocumentSettingsByType(this.allTypeDocs, buffer, bufferItem);
        buffer = this.filterDocumentByEducation(buffer, true);
      }
      return buffer;
    },

    filteredDocsSettings() {
      return this.documentSettingsByType.filter((item) => {
        if (!item.is_total) {
          let buffer = {};
          Object.keys(item)?.forEach((i) => {
            if (
              item[i] &&
              i !== "key_document" &&
              i !== "is_required" &&
              i !== "is_total"
            ) {
              buffer[i] = item[i];
            }
          });

          if (
            Object.keys(buffer)
              .map((bufferKey) => {
                if (buffer[bufferKey] === this[bufferKey]) {
                  return true;
                }
              })
              .every((a) => {
                return a;
              })
          ) {
            return item;
          }
        } else {
          return item;
        }
      });
    },
    docsSettingsWithoutDuplicates() {
      const countItems = {};

      JSON.parse(JSON.stringify(this.filteredDocsSettings))?.forEach((item) => {
        countItems[item.key_document] = countItems[item.key_document]
          ? countItems[item.key_document] + 1
          : 1;
      });
      const result = Object.keys(countItems).filter(
        (item) => countItems[item] > 1
      );

      let resultArr = [];
      result?.forEach((doc) => {
        resultArr = JSON.parse(JSON.stringify(this.filteredDocsSettings)).map(
          (i) => {
            if (i.key_document === doc && i.is_total) {
              i.priority = 0;
            } else {
              i.priority = 1;
            }
            return i;
          }
        );
      });
      return result.length > 0
        ? resultArr.filter((i) => {
            return i.priority === 1;
          })
        : this.filteredDocsSettings;
    },
    filteredDocsSettingsWithoutDuplicates() {
      let buffer = [];
      let bufferItem = {};
      if (this.type === "passport" && this.disabledFields["isCitizenRussia"]) {
        this.russianPassportDocs?.forEach((item) => {
          bufferItem = JSON.parse(
            JSON.stringify(this.docsSettingsWithoutDuplicates)
          ).find((i) => {
            return i.key_document === item;
          });
          if (bufferItem) {
            buffer.push(bufferItem);
          }
        });
      }
      if (this.type === "passport" && !this.disabledFields["isCitizenRussia"]) {
        this.foreignPassportDocs?.forEach((item) => {
          bufferItem = JSON.parse(
            JSON.stringify(this.docsSettingsWithoutDuplicates)
          ).find((i) => {
            return i.key_document === item;
          });
          if (bufferItem) {
            buffer.push(bufferItem);
          }
        });
      }
      if (this.type === "education")
        buffer = this.docsSettingsWithoutDuplicates;
      return buffer;
    },
    docsWithKeys() {
      return JSON.parse(
        JSON.stringify(this.filteredDocsSettingsWithoutDuplicates)
      )
        .map((item) => {
          item.is_pdf = !!this.isPDFDocs.includes(item.key_document);
          item.is_social = !!this.socialDocs.includes(item.key_document);
          item.type = this.getType(
            item.key_document,
            !!this.socialDocs.includes(item.key_document)
          );
          item.label = this.isAllDocsLabels[item.key_document];
          return item;
        })
        .map((doc) => {
          if (
            doc.key_document === "document_consent_parents" &&
            !this.is18yearsOld
          )
            doc.is_required = true;
          return doc;
        });
    },

    imgDocs() {
      return JSON.parse(JSON.stringify(this.docsWithKeys)).filter((i) => {
        return !i.is_pdf;
      });
    },
    pdfDocs() {
      return JSON.parse(JSON.stringify(this.docsWithKeys))
        .filter((i) => {
          return i.is_pdf;
        })
        .filter((doc) => {
          if (this.is18yearsOld) {
            return doc.key_document !== "document_consent_parents";
          } else return doc;
        });
    },

    noProjectArr() {
      let buffer = [];
      if (this.type === "passport") {
        buffer = this.disabledFields["isCitizenRussia"]
          ? JSON.parse(JSON.stringify(this.russianPassportDocs))
          : JSON.parse(JSON.stringify(this.foreignPassportDocs));
      }
      if (this.type === "education") {
        buffer = this.filterDocumentByEducation(this.allTypeDocs);
      }
      buffer = buffer.map((item) => {
        return {
          is_pdf: !!this.isPDFDocs.includes(item),
          is_social: !!this.socialDocs.includes(item),
          type: this.getType(item, !!this.socialDocs.includes(item)),
          label: this.isAllDocsLabels[item],
          key_document: item,
        };
      });
      return buffer;
    },
    noProjectImgDocs() {
      return JSON.parse(JSON.stringify(this.noProjectArr)).filter((i) => {
        return !i.is_pdf;
      });
    },
    noProjectPdfDocs() {
      return JSON.parse(JSON.stringify(this.noProjectArr))
        .filter((i) => {
          return i.is_pdf;
        })
        .filter((doc) => {
          if (this.is18yearsOld) {
            return doc.key_document !== "document_consent_parents";
          } else return doc;
        });
    },

    ...mapGetters([
      "russianPassportDocs",
      "foreignPassportDocs",
      "educationDocs",
      "allTypeDocs",
      "isPDFDocs",
      "isAllDocsLabels",
      "socialDocs",
    ]),
    ...mapState("user", ["user", "disabledFields"]),
    ...mapState(["docs"]),
  },
  methods: {
    pdfUpdate(value, key) {
      this.formData[key] = value;
      this.$store.commit("setDocument", [value, key]);
    },
    getOrientation(value) {
      return documentsLabels.find((item) => {
        return item.field === value;
      })?.orientation;
    },
    getTooltip(value) {
      return documentsLabels.find((item) => {
        return item.field === value;
      })?.tooltip;
    },
    findDocumentSettingsByType(docsArr, bufferArr, bufferItem) {
      this.documentSettings?.forEach((item) => {
        bufferItem = docsArr.find((doc) => {
          return this.project ? doc === item.key_document : doc === item;
        });
        if (bufferItem) {
          bufferArr.push(item);
        }
      });
    },
    filterDocumentByEducation(docsArr, project) {
      return docsArr.filter((doc) => {
        return (
          !this.russianPassportDocs.includes(
            project ? doc.key_document : doc
          ) &&
          !this.foreignPassportDocs.includes(
            project ? doc.key_document : doc
          ) &&
          doc !== "document_expert_order_appointment"
        );
      });
    },
    getType(item, is_social) {
      return this.type === "education" && is_social
        ? "social_documents"
        : this.type === "education" && !is_social
        ? "education"
        : "passport";
    },
  },
  async mounted() {
    this.$emit("up-docs", this.docsWithKeys);
    const isFormCorrect = await this.v$.$validate();
    this.$emit("docs-changed", isFormCorrect, this.v$.$errors);
  },
  async updated() {
    const isFormCorrect = await this.v$.$validate();
    this.$emit("docs-changed", isFormCorrect, this.v$.$errors);
  },
};
</script>

<style lang="scss"></style>
