export default [
  {
    field: "document_higher_education",
  },
  {
    field: "document_reference",
    orientation: "vertical",
    tooltip:
      "В случае невозможности загрузки справки с места обучения необходимо загрузить разворот студенческого билета с указанием формы обучения. Справка или студенческий билет должна содержать фамилию, имя, отчество (при наличии) обучающегося, форму обучения, подпись руководителя или руководителя структурного подразделения образовательной организации и печать образовательной организации.",
  },
  {
    field: "document_student_billet",
    orientation: "horizontal",
  },
  {
    field: "document_student_record_book_main_page",
    orientation: "vertical",
  },
  {
    field: "document_student_record_book_session_pages_one",
    orientation: "vertical",
    tooltip:
      "В случае отсутствия зачетной книжки необходимо предоставить справку об отсутствии задолженности. Справка должна содержать фамилию, имя, отчество (если есть) Обучающегося, форму обучения, подпись руководителя структурного подразделения образовательной организации (директора/декана) или соответствующего подразделения Образовательной организации, справка должна быть заверена печатью образовательной организации и содержать подтверждение, что Обучающийся не имеет академической задолженности.",
  },
  {
    field: "document_student_record_book_session_pages_two",
    orientation: "vertical",
  },
  {
    field: "document_student_record_book_session_pages_three",
    orientation: "vertical",
  },
  {
    field: "document_student_record_book_session_pages_four",
    orientation: "vertical",
  },
  {
    field: "document_student_record_book_session_pages_five",
    orientation: "vertical",
  },
  {
    field: "document_social_need",
    orientation: "vertical",
  },
  {
    field: "document_certificate_absence_academic_debt",
    orientation: "horizontal",
  },
  {
    field: "document_certificate_guardianship_adoption",
    orientation: "horizontal",
  },
  {
    field: "document_certificate_family_composition",
    orientation: "vertical",
    tooltip:
      "Справка из Главного управления Федеральной регистрационной службы по РТ",
  },
  {
    field: "document_reference_scholarship_amount",
    orientation: "vertical",
  },
  //passport
  {
    field: "document_passport_main_page",
    orientation: "vertical",
  },
  {
    field: "document_passport_register_page",
    orientation: "vertical",
    tooltip:
      "В случае отсутствия постоянной регистрации в РФ в паспорте гражданина РФ, необходимо загрузить временную регистрацию в РФ",
  },
  {
    field: "document_passport_6_7_page",
    orientation: "vertical",
  },
  {
    field: "document_passport_old_version_page",
    orientation: "vertical",
  },
  {
    field: "document_inn",
    orientation: "vertical",
  },
  {
    field: "document_tin",
    orientation: "vertical",
  },
  {
    field: "document_foreign_passport_main_page",
    orientation: "vertical",
  },
  {
    field: "document_foreign_passport_registration_page",
    orientation: "vertical",
  },
  {
    field: "document_foreign_passport_translate",
    orientation: "vertical",
  },
  {
    field: "document_migration_card_front",
    orientation: "horizontal",
  },
  {
    field: "document_migration_card_back",
    orientation: "horizontal",
  },
  {
    field: "document_registration_sheet_front",
    orientation: "horizontal",
  },
  {
    field: "document_registration_sheet_back",
    orientation: "horizontal",
  },
  {
    field: "document_reference_income_parent_one",
    orientation: "horizontal",
    tooltip:
      "Справку 2-НДФЛ за прошлый год можно получить на портале государственных услуг РФ. Отдельные справки с каждого места работы в прошлом году необходимо объединить в 1 pdf-файл.",
  },
  {
    field: "document_reference_income_parent_two",
    orientation: "horizontal",
    tooltip:
      "Загружается при наличии второго родителя/попечителя. Справку 2-НДФЛ за прошлый год можно получить на портале государственных услуг РФ. Отдельные справки с каждого места работы в прошлом году необходимо объединить в 1 pdf-файл.",
  },
  {
    field: "document_certificate_personal_income_student_last_3_months",
    orientation: "horizontal",
    tooltip:
      "Отдельные справки с каждого места работы за последние 3 месяца необходимо объединить в 1 pdf-файл.",
  },
  {
    field: "document_consent_parents",
    orientation: "horizontal",
    tooltip: "Согласие на обработку перс.данных (обязательно, кому нет 18 лет)",
  },
];
